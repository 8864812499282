import React, { useEffect, useState } from 'react'
import URL from '../../services/API_URL'
import { useLocation, useNavigate } from 'react-router-dom'
import ReadingQuestionPage from './ReadingQuestionPage';
import Timer from '../Timer';
import Swal from 'sweetalert2'

const IELTStestPageReading = ({ nextPage }) => {
  const [soalReading, setSoalReading] = useState(null)
  const location = useLocation();
  const navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [startTime, setStartTime] = useState(false)
  const [popup, setPopup] = useState(true)
  const [answerState, setAnswerState] = useState([])

  useEffect(() => {
    if (location.state == null) {
      navigate('/start-toefl-test')
    } else {
      if (popup) {
        Swal.fire({
          title: "Are you ready for Reading Section?",
          confirmButtonText: "Start",
          allowOutsideClick: false,
          icon: "question",
        }).then((result) => {
          if (result.isConfirmed) {
            setPopup(false)
            getSoal()
            setStartTime(true)
          }
        });
      }
    }
  }, [])

  const submit = () => {
    setBtnDisabled(true)
    for (let i = 0; i < answerState.length; i++) {
        if (answerState[i].correct_answer === answerState[i].user_answer) {
            answerState[i].is_correct = true
            answerState.push()
        } else {
            answerState[i].is_correct = false
            answerState.push()
        }
    }
    let jawaban = answerState.filter((x) => x.is_correct == true)
    let data = {
        id_user:answerState[0].id_user,
        total_correct: jawaban.length,
        type: 'reading',
        answer: answerState
    }
    URL.API_URL.post('store-answer', data)
        .then((res) => {
            Swal.fire({
                icon: "success",
                title: "Your Reading Answers Saved",
                showConfirmButton: false,
                timer: 2500,
                willClose: () => {
                    setBtnDisabled(false)
                    navigate('/thank-you')
                }
            });
        })
        .catch((err) => {
            setBtnDisabled(false)
        })
}

  const getSoal = () => {
    let formData = new FormData()
    formData.append('id', 3)
    URL.API_URL.post('load-soal', formData)
      .then((res) => {
        setSoalReading(res.data.data)
        let data = res.data.data.part_soal
        let answer = []
        for (let i = 0; i < data.length; i++) {
          let soal = data[i].soal
          for (let j = 0; j < soal.length; j++) {
            answer.push({ id_user: location.state?.id, id_soal: soal[j].id, correct_answer: soal[j].correct_answer, user_answer: '', is_correct: false })
          }
        }
        setAnswerState(answer)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <header className="bg-blue">
        <h2 className='text-white'>Reading Section</h2>
        {soalReading == null ? '' : <Timer timer={3300} category={'reading'} startTime={startTime} setInputDisabled={setInputDisabled} />}
        <div className="nav-control">
          <button onClick={submit} className='btn btn-success'>Submit</button>
        </div>
      </header>
      <div className="ielts-content">
        {soalReading == null ? '' : <ReadingQuestionPage answerState={answerState} soal={soalReading} inputDisabled={inputDisabled} />}
      </div>
    </>
  )
}

export default IELTStestPageReading