import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

import logo from '../../src/assets/images/logo.png'

const ThankYouPage = () => {

    const navigate = useNavigate()

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])

    return (
        <div className="container-thankyou">
            <div>
                <img data-aos='fade-down' data-delay-aos='750' className='mb-3' src={logo} alt="logo" height="80" />
                <h1 data-aos='fade-down' data-aos-delay='500' className='mb-3'>Congratulations!</h1>
                <p className='p' data-aos='fade-down' data-aos-delay='250' >You Have finished the NIEC TOEFL Simulation Test.</p>
                <p className='p' data-aos='fade-down' >The Result Already Sent to your email. </p>
                <button data-aos='fade-up' onClick={() => navigate('/')} type='submit' className='btn-form btn-kuning'>Back to Home</button>
            </div>
        </div>

    )
}

export default ThankYouPage