import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from '../../src/assets/images/logo.png'
import listening from '../../src/assets/images/listening.png'
import writing from '../../src/assets/images/writing.png'
import reading from '../../src/assets/images/reading.png'

const GuidlanePage = () => {
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location.state == null || location.state.free_test === 1) {
            navigate('/start-toefl-test')
        }
    }, [])

    useEffect(() => {
        AOS.init({
          duration: 1200,
        });
      }, [])

    const startTest = () => {
        navigate("/toefl-test", { state: location.state })
    }

    return (
        <section className='p-3 center-container'>
            <Container fluid>
                <Row className='justify-content-center align-items-center'>
                    <Col lg='8' sm='12'>
                        <img data-aos='fade-down' data-aos-delay='250' className='mb-3' src={logo} alt="logo" height="60" />
                        <h1 data-aos='fade-down' className='text-center'>TOEFL <span className='text-danger'>SIMULATION</span></h1>
                        <Row className='justify-content-center'>
                            <Col data-aos='fade-up' lg='2' sm='3' className='box-test text-center'>
                                <img src={listening} style={{ width: '100%' }} alt="img" />
                                <div className='pt-3 fw-bold'>Full part: </div>
                                <div>35 Minutes</div>
                                <div>3 Part</div>
                                <div className='pb-3'>50 Questions</div>
                            </Col>
                            <Col data-aos='fade-up' data-aos-delay='250' lg='2' sm='3' className='box-test text-center'>
                                <img src={reading} style={{ width: '100%' }} alt="img" />
                                <div className='pt-3 fw-bold'>Full part</div>
                                <div>25 Minutes</div>
                                <div>2 Part</div>
                                <div className='pb-3'>40 Questions</div>
                            </Col>
                            <Col data-aos='fade-up' data-aos-delay='500' lg='2' sm='3' className='box-test text-center'>
                                <img src={writing} style={{ width: '100%' }} alt="img" />
                                <div className='pt-3 fw-bold'>Full part:</div>
                                <div>55 Minutes</div>
                                <div className='pb-3'>55 Questions</div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center my-3'>
                            <Col sm='8'>
                                <p  data-aos-delay='1000' className='p mb-2'>
                                    Complete all questions within the specified time, good luck getting started
                                </p>
                                <button data-aos-delay='1250' className='btn-form btn-kuning' onClick={() => startTest()}>Start Now</button>˝
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default GuidlanePage