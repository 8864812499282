import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

const Timer = ({startTime, setInputDisabled, timer, category}) => {
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (startTime) {
            if (category === 'listening') {
                var audio = document.getElementById(`audio`);
                audio.currentTime = 0;
                setTime(timer)
                startTimer()
                audio.addEventListener("ended", function(){
                    // Swal.fire({
                    //     icon: "success",
                    //     title: "Attention",
                    //     text: "Make sure your answer before submitting, you have 5 minute to check",
                    //     showConfirmButton: true,
                    //     allowOutsideClick: false,
                    //     }).then((result) => {
                    //     if (result.isConfirmed) {
                    //         startTimer()
                    //         } 
                    //     });
                });                
            } else if (category === 'reading') {
                setTime(timer)
                startTimer()
            } else if (category === 'structure') {
                setTime(timer)
                startTimer()
            }
        }
    }, [startTime]);

    const startTimer = () => {
        let timer = setInterval(() => {
            setTime((time) => {
                if (time === 0) {
                    clearInterval(timer);
                    Swal.fire({
                        icon: "info",
                        title: "Your time finished",
                        showConfirmButton: false,
                        timer: 2500,
                        willClose: () => {
                            setInputDisabled(true)
                            sessionStorage.removeItem('pageTime')
                        }
                      });
                    return 0;
                } else {
                    sessionStorage.setItem("pageTime", time)
                    return time - 1;
                }
            });
        }, 1000);
    }
  return (
    <h4 className='text-center mt-3'>
        {`${Math.floor(time / 60)}`.padStart(2, 0)}:
        {`${time % 60}`.padStart(2, 0)}
    </h4>
  )
}

export default Timer