import React, { useState, useEffect } from 'react';
import '../styles/style.css';
import { useForm } from 'react-hook-form';
import URL from '../services/API_URL';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from '../assets/images/logo.png'

const StartTestPage = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate()
    const [btn, setBtn] = useState({ text: 'Start', disableBtn: false });

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const onSubmit = (data) => {
        setBtn({ text: 'Starting...', disableBtn: true })
        URL.API_URL.post('check-token', data)
            .then((res) => {
                setBtn({ text: 'Start', disableBtn: false })
                if (res.data.data === 'expired') {
                    setError('token_test', { type: 'custom', message: res.data.message });
                } else if (res.data.data === 'failed') {
                    setError('token_test', { type: 'custom', message: res.data.message });
                } else {
                    navigate("/guidlane-test", { state: res.data.data })    
                }
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <div className="center-container">
                <div>
                    <img data-aos='fade-down' data-aos-delay='250' className='mb-3' src={logo} alt="logo" height="80" />
                    <h1 data-aos='fade-down' className='mb-5'>TOEFL <span className='text-danger'>SIMULATION</span></h1>
                    <div data-aos='fade-up' className="box-token">
                        <h4>Input your Token Test</h4>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <input {...register("token_test")} type="password" className={`form-control token-input ${errors.token_test ? 'is-invalid' : ''}`} placeholder="Token" />
                                {errors.token_test && <div className='invalid-feedback'>{errors.token_test.message}</div>}
                            </div>
                            <button disabled={btn.disableBtn} type='submit' className='btn-form btn-kuning'>{btn.text}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartTestPage