import React from 'react'
import '../../styles/style.css'
import 'split-pane-react/esm/themes/default.css';

const ListeningQuestionPage = ({ soal, answerState, inputDisabled }) => {

    const handleChange = (e, number) => {
        const { value } = e.target
        let index = number - 1
        let _answer = [...answerState];
        _answer[index].user_answer = value
        _answer.push()
    }

    return (
        <div className='col-sm-12 p-5'>
            <audio autoPlay id="audio" >
                <source src={soal.part_soal[0].resource} type="audio/mp3" />
            </audio>
            <div className='font-16' dangerouslySetInnerHTML={{ __html: soal.text_description }} />
            {
                soal.part_soal.map((m, i) => {
                    return (
                        <>
                            <tbody>
                                <div className='row mt-4' key={i}>
                                    <div className='font-16' dangerouslySetInnerHTML={{ __html: m.text_direction }} />
                                    {
                                        m.soal?.map((s, k) => {
                                            return (
                                                <div key={k} className='col-sm-6' style={{ marginBottom: '10px' }}>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'top', fontSize: '16px' }}> <strong>{s.number}.</strong> &nbsp;&nbsp; </td>
                                                        <td >
                                                            <>
                                                                <div className='form-check'>
                                                                    <input disabled={inputDisabled ? true : false} className='form-check-input font-16' type="radio" value='A' onChange={(e) => handleChange(e, s.number)} name={`input${s.number}`} id={`input${s.number}`} /> <span className='font-16'>A. {s.opt_a}</span>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input disabled={inputDisabled ? true : false} className='form-check-input font-16' type="radio" value='B' onChange={(e) => handleChange(e, s.number)} name={`input${s.number}`} id={`input${s.number}`} /><span className='font-16'>B. {s.opt_b}</span>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input disabled={inputDisabled ? true : false} className='form-check-input font-16' type="radio" value='C' onChange={(e) => handleChange(e, s.number)} name={`input${s.number}`} id={`input${s.number}`} /><span className='font-16'>C. {s.opt_c}</span>
                                                                </div>
                                                                <div className='form-check'>
                                                                    <input disabled={inputDisabled ? true : false} className='form-check-input font-16' type="radio" value='D' onChange={(e) => handleChange(e, s.number)} name={`input${s.number}`} id={`input${s.number}`} /><span className='font-16'>D. {s.opt_d}</span>
                                                                </div>
                                                            </>
                                                        </td>
                                                    </tr>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </tbody>
                        </>
                    )
                })
            }
        </div>
    )
}

export default ListeningQuestionPage