import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import URL from '../../services/API_URL';
import Timer from '../Timer';
import ListeningQuestionPage from './ListeningQuestionPage';
import Swal from 'sweetalert2'

const IELTStestPageListening = ({ nextPage }) => {
    const navigate = useNavigate()
    const [soalListening, setSoalListening] = useState(null);
    const location = useLocation();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [popup, setPopup] = useState(true)
    const [startTime, setStartTime] = useState(false)
    const [answerState, setAnswerState] = useState([])

    useEffect(() => {
        if (location.state == null) {
            navigate('/start-toefl-test')
        }
        else if (location.state.status == 0) {
            if (popup) {
                Swal.fire({
                    title: "Are you ready for Listening Section?",
                    confirmButtonText: "Start",
                    allowOutsideClick: false,
                    icon: "question",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setPopup(false)
                        getSoal()
                        setStartTime(true)
                        //document.getElementById('audio').play();
                    }
                });
            }
        }
    }, [])

    const submit = () => {
        setBtnDisabled(true)
        for (let i = 0; i < answerState.length; i++) {
            if (answerState[i].correct_answer === answerState[i].user_answer) {
                answerState[i].is_correct = true
                answerState.push()
            } else {
                answerState[i].is_correct = false
                answerState.push()
            }
        }
        let jawaban = answerState.filter((x) => x.is_correct == true)
        let data = {
            id_user:answerState[0].id_user,
            total_correct: jawaban.length,
            type: 'listening',
            answer: answerState
        }
        console.log(data)
        URL.API_URL.post('store-answer', data)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Your Listening Answers Saved",
                    showConfirmButton: false,
                    timer: 2500,
                    willClose: () => {
                        setBtnDisabled(false)
                        nextPage(2)
                    }
                });
            })
            .catch((err) => {
                setBtnDisabled(false)
            })
    }

    const getSoal = () => {
        let formData = new FormData()
        formData.append('id', 1)
        URL.API_URL.post('load-soal', formData)
            .then((res) => {
                setSoalListening(res.data.data)
                let data = res.data.data.part_soal
                let answer = []
                for (let i = 0; i < data.length; i++) {
                    let soal = data[i].soal
                    for (let j = 0; j < soal.length; j++) {
                        answer.push({ id_user: location.state?.id, id_soal: soal[j].id, correct_answer: soal[j].correct_answer, user_answer: '', is_correct: false })
                    }
                }
                setAnswerState(answer)
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <header className="bg-blue">
                <h2 className='text-white'>Listening Section</h2>
                {soalListening == null ? '' : <Timer startTime={startTime} timer={2100} category={'listening'} setInputDisabled={setInputDisabled} />}
                <div className="nav-control">
                    <button disabled={btnDisabled ? true : false} className='btn btn-success' onClick={submit}>Submit</button>
                </div>
            </header>
            <div className="ielts-content">
                {soalListening == null ? '' : <ListeningQuestionPage soal={soalListening} answerState={answerState} inputDisabled={inputDisabled} />}
            </div>
        </>
    )
}

export default IELTStestPageListening