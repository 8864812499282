import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/style.css'
import './assets/css/themify-icons.css'

import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Index from './pages';
import InputTokenPage from './pages/InputTokenPage';
import StartTestPage from './pages/StartTestPage';
import GuidlanePage from './pages/GuidlanePage';
import HomeTestPage from './pages/HomeTestPage';
import ThankYouPage from './pages/ThankyouPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <App />
  <Router>
    <Routes>
      <Route path='/' element={<Index />} exact />
      <Route path='/start-toefl-test' element={<StartTestPage />} />
      <Route path='/input-token-test' element={<InputTokenPage />} />
      <Route path='/guidlane-test' element={<GuidlanePage />} />
      <Route path='/toefl-test' element={<HomeTestPage />} />
      <Route path='/thank-you' element={<ThankYouPage />} />
    </Routes>
  </Router>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
