import axios from 'axios';


// const url = 'http://103.41.205.87/toefl/';
// const application = 'http://103.41.205.87/api/v1/application';
const url = 'https://superstudent.niecindonesia.com/toefl/'; 
const application = 'https://superstudent.niecindonesia.com/api/v1/application';

const API_URL = axios.create({
    baseURL: url
  });

  const urlApplication = axios.create({
    baseURL: application
  })

  API_URL.interceptors.request.use(
    (config) => {
      const token = '90ef8b276661e85b8049f7bff74ed45b3908c4e467c6793e4b2539abc73f0e4fd3c145 '; 
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const URL = {
    API_URL,
    urlApplication
  }


export default URL;